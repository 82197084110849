@import "../../../../Styles/Color.scss";

.edu_footer_container {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 5;
  .edu_footer_row {
    background-color: $primaryblue;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .Navbar_Button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #e8e9ef;
    border-radius: 50px;
    margin: 4px 10px;
    width: 149px;
    // transition: 1s !important;
    -webkit-animation: glowing 1500ms infinite;

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
      -webkit-animation: glow 1500ms infinite;
      .Navbar_Button_Text {
        span {
          -webkit-animation: textglow 1500ms infinite;
        }
      }
    }
    .Navbar_Button_Icon {
      background-color: $Active_Button_Color;
      // width: 70px;
      // height: 70px;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      z-index: 999;
      .Icon {
        width: 30px;
      }
    }
    .Navbar_Button_Text {
      font-size: 20px;
      // padding: 10px 15px;
      padding-left: 10px;
      color: #9b9b9b;
      align-items: center;
      display: flex;
      width: 99px;
      span {
        -webkit-animation: glowtext 1500ms infinite;
      }
      .timer-box-design {
        margin: 0px 5px;
        padding: 7px;
        background-color: #fff;
        border-radius: 5px;
        font-size: 15px;
        font-weight: bold;
      }
    }
  }
  .disabled.blick_disable {
    .Navbar_Button_Text {
      span {
        -webkit-animation: none;
      }
    }
  }
}

@media screen and (max-width: 1140px) {
  .edu_footer_container {
    display: block !important;
    .Navbar_Button {
      display: flex !important;
      cursor: pointer;
    }
    .Navbar_Single_Icon {
      display: block !important;
      cursor: pointer;
    }
  }
}

@media screen and (min-width: 1141px) {
  .edu_footer_container {
    display: none !important;
  }
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  @keyframes glowtext {
    0% {
      font-size: 12px;
    }

    50% {
      font-size: 14px;
    }

    100% {
      font-size: 12px;
    }
  }

  .edu_footer_container {
    .Navbar_Button {
      width: 100px;
      height: 35px;
      .Navbar_Button_Text {
        font-size: 14px;
        padding-left: 8px;
      }
      .Navbar_Button_Icon {
        width: 45px;
        height: 35px;
        img {
          width: 20px !important;
          height: 20px;
        }
      }
    }
    .Navbar_Single_Icon {
      img {
        height: 25px;
        width: 25px;
        object-fit: contain;
      }
    }
  }
}

@media screen and (min-width: 981px) and (max-width: 1024px) {
  .edu_footer_container {
    .edu_footer_row {
      padding: 2px 0px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 980px) {
  .edu_footer_container {
    .edu_footer_row {
      padding: 3px 0px;
      .Navbar_Button {
        width: 140px;
        .Navbar_Button_Icon {
          width: 40px;
          height: 40px;
        }

        .Navbar_Button_Text {
          font-size: 18px;
        }
      }
      .Navbar_Single_Icon {
        img {
          height: 30px;
          width: 30px;
          object-fit: contain;
        }
      }
    }
  }
}
