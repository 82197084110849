@import "../../../../Styles/Color.scss";
@import "../../../../Styles/Variable.scss";

.Dashboard_Content_Container {
  // height: 88vh;

  .Edu_Dashboard_SideBar {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 8%;
    align-self: flex-start;
    height: auto;
    position: sticky;
  }

  .side-bar-up-scroll {
    margin-top: 0px;
  }
}

.Dashboard_Main_Container {
  .Edu_Dashboard_SideBar {
    width: 133px;
    transition: 0.3s ease-in-out;

    &:hover {
      width: 320px;
      transition: 0.3s ease-in-out;
    }

    .Edu_SideBar {
      background-color: #f8f9fc;
      border: 3px solid #fff;
      border-radius: 0px 60px 60px 0px;
      display: flex;
      height: calc(100vh - 120px);
      padding: 30px 0px;
      height: 100%;
      border-left: none;

      // position: fixed;
      .Edu_Sidebar_Icon_Container {
        background-color: $primaryblue;
        border-radius: 0px 31px 31px 0px;
        padding: 15px 15px 15px 0px;

        a {
          padding: 7px 0px 7px 0px;
          border-radius: 0px 50px 50px 0px;
          text-decoration: none;
          display: flex;

          .Edu_Icon_Container {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 25px;

            img {
              width: 20px;
              opacity: 1;
            }
          }

          &.active_nav_Side {
            background-color: #fff;
            display: flex;
            padding: 6px 6px;

            .Edu_Icon_Container {
              background-color: $Active_Button_Color;
              width: 45px;
              height: 45px;
              border-radius: 50%;

              img {
                opacity: 1;
              }
            }
          }
        }
      }

      .Edu_Sidebar_Text {
        margin: 15px 30px;
        display: flex;
        flex-direction: column;

        .active_side_menu {
          color: $primaryblue !important;
        }

        a {
          font-weight: 600;
          padding: 18px 0px 17px 0px;

          border-bottom: 1px solid $title_color;
          width: 160px;
          font-size: 16px !important;
          text-decoration: none;
          // @extend %Common_Educator_15_Regular_Font;
          color: $title_color !important;
        }

        a:last-child {
          border-bottom: 0px solid #444 !important;
        }
      }
    }
  }
}

.Edu_Sidebar_Text {
  visibility: hidden;
  transition: 0.1s ease-in-out;
}

.Edu_SideBar:hover .Edu_Sidebar_Text {
  visibility: visible;
  transition: 0.18s ease-in-out;
}

@media screen and (max-width: 1024px) {
  .Dashboard_Main_Container {
    .Dashboard_Content_Container {
      display: block;

      .Edu_Dashboard_SideBar {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .Dashboard_Main_Container {
    .Dashboard_Content_Container {
      .Edu_Dashboard_SideBar {
        display: none;
      }
    }
  }
}

@media (min-width: 1025px) and (max-width: 1140px) {
  .Edu_Dashboard_Content {
    .class-history-banner {
      padding: 20px 10px;
      gap: 15px !important;
    }
  }

  .Edu_Dashboard_Sidebar {
    display: none;
  }
}

@media (min-width: 1026px) and (max-width: 1140px) {
  .Dashboard_Main_Container {
    .Edu_Dashboard_SideBar {
      width: 113px;
      // display: none;
    }
  }
}

@media (min-width: 1026px) and (max-width: 1280px) {
  .Dashboard_Main_Container {
    .Edu_Dashboard_SideBar {
      .Edu_SideBar {
        padding: 20px 0px;

        .Edu_Sidebar_Icon_Container {
          a {
            padding: 4px 0px 4px 0px;

            .Edu_Icon_Container {
              // height: 35px;
              img {
                width: 22px;
              }
            }
          }
        }

        .Edu_Sidebar_Text {
          margin: 22px 30px;
          a {
            padding: 14px 0;
          }
        }
      }
    }
  }
}

@media (min-width: 1141px) and (max-width: 1280px) {
  .Dashboard_Main_Container {
    .Edu_Dashboard_SideBar {
      width: 112px;
      &:hover {
        width: 295px;
        transition: 0.3s ease-in-out;
      }
      .Edu_SideBar {
        .Edu_Sidebar_Text {
          margin: 22px 20px;
        }
        .Edu_Sidebar_Icon_Container {
          a {
            .Edu_Icon_Container {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1281px) and (max-width: 1440px) {
  .Dashboard_Main_Container {
    .Edu_Dashboard_SideBar {
      .Edu_SideBar {
        padding: 20px 0px;

        .Edu_Sidebar_Icon_Container {
          a {
            padding: 4px 0px 4px 0px;

            .Edu_Icon_Container {
              // height: 35px;
              img {
                width: 22px;
              }
            }
          }
        }

        .Edu_Sidebar_Text {
          margin: 22px 30px;

          a {
            padding: 14px 0;
          }
        }
      }
    }
  }
}

@media (min-width: 1441px) and (max-width: 1680px) {
  .Dashboard_Main_Container {
    .Edu_Dashboard_SideBar {
      .Edu_SideBar {
        padding: 20px 0px;

        .Edu_Sidebar_Icon_Container {
          a {
            padding: 4px 0px 4px 0px;

            .Edu_Icon_Container {
              img {
                width: 22px;
              }
            }
          }
        }

        .Edu_Sidebar_Text {
          margin: 22px 30px;

          a {
            padding: 14px 0;
          }
        }
      }
    }
  }
}

@media (min-width: 1681px) and (max-width: 1880px) {
  .Dashboard_Content_Container {
    .side-bar-up-scroll {
      margin-top: 135px;
    }
  }
  .Dashboard_Main_Container {
    .Edu_Dashboard_SideBar {
      .Edu_SideBar {
        padding: 20px 0px;

        .Edu_Sidebar_Icon_Container {
          a {
            padding: 4px 0px 4px 0px;

            .Edu_Icon_Container {
              img {
                width: 22px;
              }
            }
          }
        }

        .Edu_Sidebar_Text {
          margin: 22px 30px;

          a {
            padding: 14px 0;
          }
        }
      }
    }
  }
}
